import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
// Components
import BorderCard from './BorderCard';
import GameCard from './GameCard';
import GameProviderHeader from './GameProviderHeader';
import GameCardViewAll from './GameCardViewAll';
// Models
import GameModel from '../@types/models/GameModel';

type Props = {
  games: null | Array<GameModel>;
  providerId?: string;
  displayViewAllButton?: boolean;
  displaySearch?: boolean;
  isMenu?: boolean;
  onClick?: (gameId: string) => void;
};

const GameList = ({
  games,
  providerId,
  displayViewAllButton,
  displaySearch,
  isMenu,
  onClick,
}: Props) => {
  if (!games || !games.length) {
    return null;
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [gameNameFilter, setGameNameFilter] = useState<string>('');

  // Number elements on the page is related to a context as menu or page.
  const sizeBreaks = isMenu
    ? {
        xs: 6,
        sm: 6,
        md: 3,
        lg: 3,
      }
    : {
        xs: 4,
        sm: 3,
        md: 2,
        lg: 2,
      };
  const spacing = isMenu || isMobile ? 1 : 2;

  const filteredGamesList = (games || []).filter((gameModel: GameModel) =>
    gameNameFilter.length
      ? gameModel.name?.toLowerCase().indexOf(gameNameFilter) != -1
      : true
  );
  return (
    <Box key={providerId} sx={{ marginTop: '20px' }}>
      <GameProviderHeader
        providerId={providerId}
        displaySearch={displaySearch}
        setSearchFilter={(gameName: string) => setGameNameFilter(gameName)}
        availableSearchElements={filteredGamesList?.length}
      />
      {filteredGamesList && filteredGamesList.length && (
        <Grid key={gameNameFilter} container={true} spacing={spacing}>
          {filteredGamesList.map((gameModel: GameModel, index: number) => (
            <Grid
              key={`${gameModel.id}-${index}`}
              item={true}
              xs={sizeBreaks.xs}
              sm={sizeBreaks.sm}
              md={sizeBreaks.md}
              lg={sizeBreaks.lg}
            >
              <GameCard gameModel={gameModel} onClick={onClick} />
            </Grid>
          ))}
          {displayViewAllButton && (
            <Grid
              item={true}
              xs={sizeBreaks.xs}
              sm={sizeBreaks.sm}
              md={sizeBreaks.md}
              lg={sizeBreaks.lg}
            >
              <GameCardViewAll providerId={providerId} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default GameList;
