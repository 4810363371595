import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
// Components
import BorderCard from './BorderCard';
// Models
import GameModel from '../@types/models/GameModel';
// SVG
import { PlaySvg, InfoSvg } from '@/shared/svg/index';

const GameCategoryDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -1,
  right: -1,
  padding: '1px 10px',
  fontFamily: 'google_sansmedium, sans-serif',
  fontSize: 10,
  zIndex: 1,
  color: '#ffffff',
  borderRadius: '0px 0px 0px 8px',
  borderBottom: '1px solid #384766',
  borderLeft: '1px solid #384766',
  background: 'linear-gradient(180deg, #182740 0%, #02173A 100%)',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '81px',
  textOverflow: 'ellipsis',

  '@media (max-width: 600px)': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '84px',
    textOverflow: 'ellipsis',
  },
}));

const GameNameDiv = styled('div')(({ theme }) => ({
  color: '#A3ADC2',
  fontSize: 12,
  fontFamily: 'google_sansmedium, sans-serif',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: 10,
}));

const GameCardImageWrapperDiv = styled('div')(({ theme }) => ({
  border: '1px solid #000',
  overflow: 'hidden',
  borderRadius: 10,
}));

type Props = {
  gameModel: GameModel;
  // size?: number | string,
  displayName?: boolean;
  displayCategory?: boolean;
  onClick?: (gameId: string) => void;
};

const GameCard = ({
  gameModel,
  displayName,
  displayCategory,
  onClick,
}: Props) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  // Const.
  const cardImageDefaultPath = `${process.env.REACT_APP_GAME_S3_PATH}${gameModel.providerId}/noimage/${gameModel.providerId}-no-image.png`;

  const cardImagePath =
    gameModel.thumbnailUrl ||
    `${process.env.REACT_APP_GAME_S3_PATH}${gameModel.providerId}/${gameModel.id}/thumbnail_320x320.jpg`;
  const gameLink = `/game/${gameModel.providerId}/${gameModel.id}/`;

  function toCamelCase(input: string): string {
    let titleCaseWords: string[] = [];

    // Check if the string contains hyphens
    if (input.includes('-')) {
      // Split the string by hyphen "-"
      let words = input.split('-');
      // Process each word to capitalize the first letter and make the rest lowercase
      titleCaseWords = words.map(
        word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    } else {
      // Split the string by space
      let wordspace = input.split(' ');
      // Process each word to capitalize the first letter and make the rest lowercase
      titleCaseWords = wordspace.map(
        word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    }

    // Join the words with space
    return titleCaseWords.join(' ');
  }
  if (gameModel.providerId === 'inbwn')
    console.log(gameModel, 'gameModel-sfjao');
  return (
    <BorderCard key={gameModel.id}>
      <CardActionArea
        component={Link}
        to={gameLink}
        onClick={() => {
          onClick && onClick(gameModel.id);
        }}
      >
        {displayCategory === undefined || displayCategory === true ? (
          <GameCategoryDiv>
            {toCamelCase(gameModel.category)}
            {gameModel.isSupportDemo ? '-DEMO' : ''}
          </GameCategoryDiv>
        ) : null}
        <GameCardImageWrapperDiv>
          <CardMedia
            component='img'
            loading='lazy'
            alt={gameModel.name}
            image={cardImagePath}
            onError={(e: any) => {
              e.target.src = cardImageDefaultPath;
            }}
            onLoad={() => setIsImageLoaded(true)}
          />
          {!isImageLoaded && (
            <Skeleton
              variant='rectangular'
              //width={100}
              //height="100px"
              animation='wave'
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                '&::after': {
                  background: `linear-gradient( 90deg, transparent, #B4AFA524, transparent)`,
                },
              }}
            />
          )}
          {displayName === undefined || displayName === true ? (
            <GameNameDiv>
              {/* {gameModel.displayOrder}-{gameModel.name} */}
              {gameModel.name}
            </GameNameDiv>
          ) : null}
        </GameCardImageWrapperDiv>
      </CardActionArea>
    </BorderCard>
  );
};

export default GameCard;
